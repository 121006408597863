.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.cardColor {
  background: rgb(195, 255, 145);
  background: linear-gradient(
    315deg,
    rgba(195, 255, 145, 1) 0%,
    rgba(255, 255, 255, 1) 63%
  );
}

.list-inner {
  height: 100%;
  max-height: 400px;
}

@media screen and (max-height: 750px) {
  .list-inner {
    max-height: 320px;
  }
}

@media screen and (min-height: 850px) {
  .list-inner {
    max-height: 470px;
  }
}


