.cards,
.cards2 {
  /* max-width: 1200px; */
  margin: 0 auto;
  display: grid;
  grid-gap: 1.5rem;
  margin-top: 30px;
  /* background-color: red; */
}

/* Screen larger than 600px? 2 column */
@media (min-width: 600px) {
  .cards {
    grid-template-columns: repeat(2, 1fr);
  }

  .cards2 {
    grid-template-columns: repeat(4, 1fr);
  }
}

/* Screen larger than 900px? 3 columns */
@media (min-width: 900px) {
  .cards {
    grid-template-columns: repeat(3, 1fr);
  }
  .cards2 {
    grid-template-columns: repeat(6, 1fr);
    padding: 0 10px;
  }
}

.imgPreview{
    width: 50px;
    height: 40px;
    margin-top: 10px;
    border-radius: 12px;
}